import {
  createCustomTransactions,
  CustomAccount,
  getManualEntriesForPayroll,
  createMatchingCustomTransactionsFromManualEntries,
  validateManualEntries,
  CreateManualEntry,
} from './importTransactionsHelpers'
import React, { useEffect, useState } from 'react'
import { LAYER_API_URL } from '../../Root'
import { textAreaStyle } from './ImportTransactionData'

export interface ImportPayrollManualEntriesProps {
  demoName: string
  businessId: string
  bearerToken: string
  defaultCustomAccount: CustomAccount
  customAccountLedgerAccountId: string
}

export const ImportPayrollManualEntries = ({
  demoName,
  businessId,
  bearerToken,
  defaultCustomAccount,
  customAccountLedgerAccountId,
}: ImportPayrollManualEntriesProps) => {
  const [manualEntriesPayload, setManualEntriesPayload] = useState<string>('')
  useEffect(() => {
    if (defaultCustomAccount != null) {
      const initialManualEntries = getManualEntriesForPayroll(
        customAccountLedgerAccountId,
      )
      setManualEntriesPayload(JSON.stringify(initialManualEntries, null, 2))
    }
  }, [demoName, defaultCustomAccount])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const parsedManualEntriesPayload = JSON.parse(manualEntriesPayload)
      if (!validateManualEntries(parsedManualEntriesPayload)) {
        throw new Error('Invalid manual entry payload structure')
      }
      const manualEntries = parsedManualEntriesPayload as CreateManualEntry[]
      manualEntries.forEach(async entry => {
        const manualEntriesResponse = await fetch(
          `${LAYER_API_URL}/v1/businesses/${businessId}/ledger/journal-entries`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(entry),
          },
        )
        if (!manualEntriesResponse.ok)
          console.error(`${manualEntriesResponse.status}`)
      })

      const customTransactions =
        createMatchingCustomTransactionsFromManualEntries(
          businessId,
          manualEntries,
        )

      const responseData = await createCustomTransactions(
        businessId,
        bearerToken,
        defaultCustomAccount.id,
        customTransactions,
      )
      alert('Payroll manual entries imported successfully!')
    } catch (error) {
      console.error('Error in payload or submission:', error)
      alert(`Failed to submit: ${error}`)
    }
  }

  return (
    <fieldset
      style={{
        minWidth: '400px',
        maxWidth: '600px',
        padding: '20px',
        border: '2px solid black',
        borderRadius: '5px',
        flex: '1',
      }}
    >
      <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
        Import Payroll Manual Entries
      </legend>
      <form onSubmit={handleSubmit}>
        <textarea
          style={textAreaStyle}
          value={manualEntriesPayload}
          onChange={e => setManualEntriesPayload(e.target.value)}
        />
        <div style={{ textAlign: 'right' }}>
          <br />
          <button className='admin-button' type='submit'>
            Submit Payroll Manual Entries
          </button>
        </div>
      </form>
    </fieldset>
  )
}
