import {
  createCustomTransactions,
  CustomAccount,
  getExpenseCustomTransactions,
  validateCustomTransactions,
} from './importTransactionsHelpers'
import React, { useEffect, useState } from 'react'
import { textAreaStyle } from './ImportTransactionData'

export interface ImportBankTransactionsProps {
  demoName: string
  businessId: string
  bearerToken: string
  defaultCustomAccount: CustomAccount
}

export const ImportBankTransactions = ({
  demoName,
  businessId,
  bearerToken,
  defaultCustomAccount,
}: ImportBankTransactionsProps) => {
  const [expensePayload, setExpensePayload] = useState<string>('')
  useEffect(() => {
    if (defaultCustomAccount != null) {
      const initialExpenses = getExpenseCustomTransactions(demoName)
      setExpensePayload(JSON.stringify(initialExpenses, null, 2))
    }
  }, [demoName, defaultCustomAccount])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const parsedPayload = JSON.parse(expensePayload)
      if (!validateCustomTransactions(parsedPayload)) {
        throw new Error('Invalid transaction payload structure')
      }

      const responseData = await createCustomTransactions(
        businessId,
        bearerToken,
        defaultCustomAccount.id,
        parsedPayload,
      )
      alert('Bank transactions imported successfully!')
    } catch (error) {
      console.error('Error in payload or submission:', error)
      alert(`Failed to submit: ${error}`)
    }
  }

  return (
    <fieldset
      style={{
        minWidth: '400px',
        maxWidth: '600px',
        padding: '20px',
        border: '2px solid black',
        borderRadius: '5px',
        flex: '1',
      }}
    >
      <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
        Import Bank Transactions
      </legend>
      <form onSubmit={handleSubmit}>
        <textarea
          style={textAreaStyle}
          value={expensePayload}
          onChange={e => setExpensePayload(e.target.value)}
        />
        <div style={{ textAlign: 'right' }}>
          <br />
          <button className='admin-button' type='submit'>
            Submit Transactions
          </button>
        </div>
      </form>
    </fieldset>
  )
}
