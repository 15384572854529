import { formatISO } from 'date-fns'
import { LAYER_API_URL, DEFAULT_ACCOUNT_NAME } from '../../Root'

type Payment = {
  method: string
  fee: number
  amount: number
}

type InvoiceLineItem = {
  description: string
  product: string
  unit_price: number
  quantity: number
}

export type TransactionMatch = {
  transaction_id: string
  suggested_match_id: string
}

export type Invoice = {
  sent_at: string
  recipient_name: string
  line_items: InvoiceLineItem[]
  payments: Payment[]
}

export type CustomTransactionParams = {
  external_id: string
  direction: BankTransactionDirection
  description: string
  amount: number
  date: string
  merchant_name: string
  currency_code: 'USD'
}

export type ExternalAccountSource = 'PLAID' | 'STRIPE' | 'CUSTOM' | 'UNIT'

export type ExternalAccount = {
  id: string
  external_account_source: ExternalAccountSource
  external_account_name: string
  external_account_external_id: string
  ledger_account_id: string
}

export type BankTransactionDirection = 'DEBIT' | 'CREDIT'

export type AccountType = 'depository' | 'credit'

export type AccountSubType = 'checking' | 'credit card'

export type CustomAccount = {
  id: string
  mask: string
  account_name: string
  account_type: AccountType
  account_subtype: AccountSubType
  ledger_account_id: string
}

export type AccountId = {
  type: 'AccountId'
  id: string
}

export type AcconutStableName = {
  type: 'StableName'
  stable_name: string
}

export type AccountIdentifier = AccountId | AcconutStableName

export type CreateManualEntryLineItem = {
  account_identifier: AccountIdentifier
  amount: number
  direction: BankTransactionDirection
}

export type CreateManualEntry = {
  external_id?: string
  entry_at: string
  created_by: string
  memo: string
  line_items: CreateManualEntryLineItem[]
}

export const getAccount = async (
  businessId: string,
  bearerToken: string,
  accountId: string,
): Promise<ExternalAccount> => {
  const response = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/external-accounts/${accountId}`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    },
  )
  const responseData = await response.json()
  return responseData.data as ExternalAccount
}

export const getAccounts = async (
  businessId: string,
  bearerToken: string,
): Promise<ExternalAccount[]> => {
  const response = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/external-accounts`,
    {
      method: 'get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    },
  )
  const responseData = await response.json()
  const externalAccounts = responseData.data
    .external_accounts as ExternalAccount[]
  return externalAccounts
}

export const getCustomAccountById = async (
  businessId: string,
  bearerToken: string,
  customAccountId: string,
) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  }
  const responseData = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/custom-accounts/${customAccountId}`,
    options,
  ).then(response => response.json())
  return responseData.data as CustomAccount
}

export const createCustomAccount = async (
  businessId: string,
  bearerToken: string,
  externalId: string,
  mask: string,
  accountName: string,
  institutionName: string,
  accountType: AccountType,
  accountSubType: AccountSubType,
) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    body: `{"external_id":"${externalId}","mask":"${mask}","account_name":"${accountName}","institution_name":"${institutionName}","account_type":"${accountType}","account_subtype":"${accountSubType}"}`,
  }
  const responseData = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/custom-accounts/`,
    options,
  ).then(response => response.json())
  return responseData.data as CustomAccount
}

export const getOrCreateDefaultCustomAccount = async (
  businessId: string,
  bearerToken: string,
): Promise<CustomAccount> => {
  const accounts = await getAccounts(businessId, bearerToken)
  const defaultAccount = accounts.find(account => {
    return (
      account.external_account_source == 'CUSTOM' &&
      account.external_account_name == DEFAULT_ACCOUNT_NAME &&
      account.external_account_external_id == businessId
    )
  })
  if (defaultAccount != undefined) {
    const customAccount = await getCustomAccountById(
      businessId,
      bearerToken,
      defaultAccount.id,
    )
    return customAccount
  }

  const createdDefaultAccount = await createCustomAccount(
    businessId,
    bearerToken,
    businessId, // using the business ID as an external ID for the default custom account
    '4321',
    DEFAULT_ACCOUNT_NAME,
    'Layer Demo',
    'depository',
    'checking',
  )

  return createdDefaultAccount
}

export const createCustomTransactions = async (
  businessId: string,
  bearerToken: string,
  customAccountId: string,
  customTransactionParams: CustomTransactionParams[],
) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    body: `{"transactions": ${JSON.stringify(customTransactionParams)}}`,
  }
  const responseData = await fetch(
    `${LAYER_API_URL}/v1/businesses/${businessId}/custom-accounts/${customAccountId}/transactions`,
    options,
  ).then(response => response.json())
  console.log(responseData)
  return
}

const validateInvoiceLineItem = (item: any): item is InvoiceLineItem => {
  return (
    typeof item.description === 'string' &&
    typeof item.product === 'string' &&
    typeof item.unit_price === 'number' &&
    typeof item.quantity === 'number'
  )
}

const validatePayment = (payment: any): payment is Payment => {
  return (
    typeof payment.method === 'string' &&
    typeof payment.fee === 'number' &&
    typeof payment.amount === 'number'
  )
}

export const validateInvoices = (data: any): data is Invoice[] => {
  return (
    Array.isArray(data) &&
    data.every(invoice => {
      return (
        typeof invoice.sent_at === 'string' &&
        typeof invoice.recipient_name === 'string' &&
        Array.isArray(invoice.line_items) &&
        invoice.line_items.every(validateInvoiceLineItem) &&
        Array.isArray(invoice.payments) &&
        invoice.payments.every(validatePayment)
      )
    })
  )
}

export const validateCustomTransactions = (
  data: any,
): data is CustomTransactionParams[] => {
  return (
    Array.isArray(data) &&
    data.every(transaction => {
      return (
        typeof transaction.external_id === 'string' &&
        (transaction.direction === 'DEBIT' ||
          transaction.direction === 'CREDIT') &&
        typeof transaction.description === 'string' &&
        typeof transaction.amount === 'number' &&
        typeof transaction.date === 'string' &&
        typeof transaction.merchant_name === 'string' &&
        transaction.currency_code === 'USD'
      )
    })
  )
}

const WEEKLY_AVERAGE_PAYOUT = 5000_00

const randomInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const past12Months = (): Array<[number, number]> => {
  const currentDate = new Date()
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth() + 1
  const monthsList: Array<[number, number]> = []
  for (let i = 0; i < 12; i++) {
    monthsList.push([year, month])
    if (month === 1) {
      month = 12
      year -= 1
    } else {
      month -= 1
    }
  }
  return monthsList.reverse()
}

const randomDate = (year: number, month: number): Date => {
  const date = new Date(year, month, 0)
  const lastDayOfMonth = date.getDate()
  const randomDay = Math.floor(Math.random() * lastDayOfMonth) + 1
  return new Date(year, month - 1, randomDay, 15, 46, 54, 255)
}

const past12MonthsFridays = (): Date[] => {
  const currentDate = new Date()
  currentDate.setHours(12, 0, 0, 0)
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth() + 1
  let fridaysList: Date[] = []
  function findFridays(year: number, month: number): Date[] {
    const fridays: Date[] = []
    const daysInMonth = new Date(year, month, 0).getDate()

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month - 1, day, 12, 0)
      if (date.getDay() === 5) {
        fridays.push(date)
      }
    }
    return fridays
  }
  for (let i = 0; i < 12; i++) {
    fridaysList = fridaysList.concat(findFridays(year, month))
    if (month === 1) {
      month = 12
      year -= 1
    } else {
      month -= 1
    }
  }
  return fridaysList
    .filter(friday => friday <= currentDate)
    .reverse()
    .slice(1)
}

export const getInvoices = (demoName: string): Invoice[] => {
  const invoices: Invoice[] = []
  const recipientNameBase = `${demoName} `
  const pastFridays = past12MonthsFridays()
  for (const transactionDate of pastFridays) {
    const transactionAmount = Math.round(
      (WEEKLY_AVERAGE_PAYOUT * randomInRange(50, 150)) / 100,
    )
    const payment: Payment = {
      method: 'ACH',
      fee: 0,
      amount: transactionAmount,
    }

    const lineItem: InvoiceLineItem = {
      description: 'Widget sales',
      product: 'Widgets',
      unit_price: transactionAmount,
      quantity: 1,
    }

    const invoice: Invoice = {
      sent_at: formatISO(transactionDate),
      recipient_name:
        recipientNameBase + randomInRange(10000, 100000).toString(),
      line_items: [lineItem],
      payments: [payment],
    }

    invoices.push(invoice)
  }

  return invoices
}

export const getExpenseCustomTransactions = (
  demoName: string,
): CustomTransactionParams[] => {
  const externalIdBase = `12345-${demoName}-custom-expense-`
  let itr = 1
  const transactions: CustomTransactionParams[] = []
  const today = new Date()

  const baseTransaction = {
    direction: 'DEBIT' as BankTransactionDirection,
    currency_code: 'USD' as const,
  }

  for (const [year, month] of past12Months()) {
    // Rent
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'WeWork',
      amount: 250000,
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'WeWork',
    })
    itr++

    // Utilities
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'PG&E',
      amount: Math.round(50000 * (randomInRange(80, 120) / 100)),
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'PG&E',
    })
    itr++

    // Insurance
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'State Farm',
      amount: 50000,
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'State Farm',
    })
    itr++

    // Advertisements
    transactions.push({
      ...baseTransaction,
      external_id: `${externalIdBase}${itr}`,
      description: 'Facebook Advertisements',
      amount: Math.round(100000 * (randomInRange(80, 120) / 100)),
      date: formatISO(new Date(year, month - 1, 1, 12, 0)),
      merchant_name: 'Facebook',
    })
    itr++

    // One-off travel
    if (new Date(year, month - 1, 10, 12) < today) {
      transactions.push({
        ...baseTransaction,
        external_id: `${externalIdBase}${itr}`,
        description: 'United Airlines',
        amount: Math.round(50000 * (randomInRange(50, 150) / 100)),
        date: formatISO(new Date(year, month - 1, 10, 12)),
        merchant_name: 'United Airlines',
      })
      itr++

      transactions.push({
        ...baseTransaction,
        external_id: `${externalIdBase}${itr}`,
        description: 'AirBnB',
        amount: Math.round(20000 * (randomInRange(50, 150) / 100)),
        date: formatISO(new Date(year, month - 1, 10, 12)),
        merchant_name: 'AirBnB',
      })
      itr++
    }

    // Uber (multiple transactions)
    for (let i = 0; i < 5; i++) {
      const uberTransactionDate = randomDate(year, month)
      if (uberTransactionDate < today) {
        transactions.push({
          ...baseTransaction,
          external_id: `${externalIdBase}${itr}`,
          description: 'Uber',
          amount: Math.round(3000 * (randomInRange(25, 200) / 100)),
          date: formatISO(uberTransactionDate),
          merchant_name: 'Uber',
        })
        itr++
      }
    }

    // Restaurants (multiple transactions)
    const restaurantNames = [
      'Chipotle',
      'Sweetgreen',
      'Panera Bread',
      'Starbucks',
    ]
    for (let i = 0; i < 10; i++) {
      const restaurantTransactionDate = randomDate(year, month)
      if (restaurantTransactionDate < today) {
        const restaurantName =
          restaurantNames[randomInRange(0, restaurantNames.length - 1)]
        transactions.push({
          ...baseTransaction,
          external_id: `${externalIdBase}${itr}`,
          description: restaurantName,
          amount: Math.round(8000 * (randomInRange(50, 150) / 100)),
          date: formatISO(restaurantTransactionDate),
          merchant_name: restaurantName,
        })
        itr++
      }
    }
  }

  return transactions
}

export const createMatchingCustomTransactionsFromInvoices = (
  invoices: Invoice[],
  demoName: string,
): CustomTransactionParams[] => {
  let transactionIdCounter = 1
  const matchingTransactions: CustomTransactionParams[] = []
  const externalIdBase = `12345-${demoName}-custom-match-`
  const descriptionBase = 'Widget bulk order #31415-'

  for (const invoice of invoices) {
    for (const payment of invoice.payments) {
      const matchingTransaction: CustomTransactionParams = {
        external_id: `${externalIdBase}${transactionIdCounter}`,
        direction: 'CREDIT',
        description: `${descriptionBase}${transactionIdCounter}`,
        amount: payment.amount,
        date: invoice.sent_at,
        merchant_name: invoice.recipient_name,
        currency_code: 'USD',
      }

      matchingTransactions.push(matchingTransaction)
      transactionIdCounter += 1
    }
  }

  return matchingTransactions
}

const validateAccountIdentifier = (
  identifier: any,
): identifier is AccountIdentifier => {
  if (!identifier || typeof identifier !== 'object') {
    return false
  }

  if (identifier.type === 'AccountId') {
    return typeof identifier.id === 'string'
  }

  if (identifier.type === 'StableName') {
    return typeof identifier.stable_name === 'string'
  }

  return false
}

const validateLineItem = (item: any): item is CreateManualEntryLineItem => {
  return (
    typeof item === 'object' &&
    item !== null &&
    typeof item.amount === 'number' &&
    (item.direction === 'DEBIT' || item.direction === 'CREDIT') &&
    validateAccountIdentifier(item.account_identifier)
  )
}

const validateManualEntry = (entry: any): entry is CreateManualEntry => {
  return (
    typeof entry === 'object' &&
    entry !== null &&
    typeof entry.entry_at === 'string' &&
    typeof entry.created_by === 'string' &&
    typeof entry.memo === 'string' &&
    Array.isArray(entry.line_items) &&
    entry.line_items.every(validateLineItem) &&
    (entry.external_id === undefined || typeof entry.external_id === 'string')
  )
}

export const validateManualEntries = (
  payload: any,
): payload is CreateManualEntry[] => {
  return Array.isArray(payload) && payload.every(validateManualEntry)
}

export const getManualEntriesForPayroll = (
  customAccountId: string,
): CreateManualEntry[] => {
  const entries: CreateManualEntry[] = []
  const monthsList = past12Months()

  // Base amounts for different payroll categories
  const baseAmounts = {
    PAYROLL_REGULAR_WAGES: 450000, // $4,500 base
    PAYROLL_CONTRACTORS: 250000, // $2,500 base
    PAYROLL_BENEFITS: 120000, // $1,200 base
    PAYROLL_TAXES: 180000, // $1,800 base
    PAYROLL_FEES: 50000, // $500 base
  }

  for (const [year, month] of monthsList) {
    const entryDate = new Date(year, month - 1, 15, 12, 0, 0)
    const today = new Date()
    if (entryDate > today) {
      continue
    }

    const lineItems: CreateManualEntryLineItem[] = []
    let totalDebit = 0

    Object.entries(baseAmounts).forEach(([category, baseAmount]) => {
      const variation = 0.9 + Math.random() * 0.2
      const amount = Math.round(baseAmount * variation)
      totalDebit += amount

      lineItems.push({
        account_identifier: {
          type: 'StableName',
          stable_name: category as any,
        },
        amount,
        direction: 'DEBIT',
      })
    })

    lineItems.push({
      account_identifier: {
        type: 'AccountId',
        id: customAccountId,
      },
      amount: totalDebit,
      direction: 'CREDIT',
    })

    const entry: CreateManualEntry = {
      entry_at: formatISO(entryDate),
      created_by: 'Customizable Demo',
      memo: `Payroll for ${entryDate.toLocaleString('default', { month: 'long' })} ${year}`,
      line_items: lineItems,
    }

    entries.push(entry)
  }

  return entries
}

export const createMatchingCustomTransactionsFromManualEntries = (
  businessId: string,
  manualEntries: CreateManualEntry[],
): CustomTransactionParams[] => {
  const transactions: CustomTransactionParams[] = []
  let transactionCounter = 1

  for (const entry of manualEntries) {
    const creditLineItem = entry.line_items.find(
      item => item.direction === 'CREDIT',
    )

    if (creditLineItem) {
      // Create matching transaction for the credit amount
      const transaction: CustomTransactionParams = {
        external_id: `manual-entry-match-${businessId}-${transactionCounter}`,
        direction: 'DEBIT',
        description: entry.memo,
        amount: creditLineItem.amount,
        date: entry.entry_at,
        merchant_name: 'Payroll Provider',
        currency_code: 'USD',
      }

      transactions.push(transaction)
      transactionCounter++
    }
  }

  return transactions
}
