import {
  getInvoices,
  validateInvoices,
  createMatchingCustomTransactionsFromInvoices,
  createCustomTransactions,
  CustomAccount,
} from './importTransactionsHelpers'
import React, { useEffect, useState } from 'react'
import { LAYER_API_URL } from '../../Root'
import { textAreaStyle } from './ImportTransactionData'

export interface ImportInvoicesProps {
  demoName: string
  businessId: string
  bearerToken: string
  defaultCustomAccount: CustomAccount
}

export const ImportInvoices = ({
  demoName,
  businessId,
  bearerToken,
  defaultCustomAccount,
}: ImportInvoicesProps) => {
  const [invoicePayload, setInvoicePayload] = useState<string>('')
  useEffect(() => {
    if (defaultCustomAccount != null) {
      const initialInvoices = getInvoices(demoName)
      setInvoicePayload(JSON.stringify(initialInvoices, null, 2))
    }
  }, [demoName, defaultCustomAccount])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      const parsedInvoicePayload = JSON.parse(invoicePayload)
      if (!validateInvoices(parsedInvoicePayload)) {
        throw new Error('Invalid invoice payload structure')
      }

      const invoiceResponse = await fetch(
        `${LAYER_API_URL}/v1/businesses/${businessId}/invoices/bulk`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(parsedInvoicePayload),
        },
      )
      const invoiceResponseData = await invoiceResponse.json()
      if (!invoiceResponse.ok) throw new Error('Invoice API call failed')

      const matchingTxns = await createMatchingCustomTransactionsFromInvoices(
        parsedInvoicePayload,
        demoName,
      )
      const txnsResponse = await createCustomTransactions(
        businessId,
        bearerToken,
        defaultCustomAccount.id,
        matchingTxns,
      )

      alert('Invoices imported successfully!')
    } catch (error) {
      console.error('Error in payload or submission:', error)
      alert(`Failed to import invoices: ${error}`)
    }
  }
  return (
    <fieldset
      style={{
        minWidth: '400px',
        maxWidth: '600px',
        padding: '20px',
        border: '2px solid black',
        borderRadius: '5px',
        flex: '1',
      }}
    >
      <legend style={{ fontSize: '1.2em', padding: '0 10px' }}>
        Import Invoices
      </legend>
      <form onSubmit={handleSubmit}>
        <textarea
          style={textAreaStyle}
          value={invoicePayload}
          onChange={e => setInvoicePayload(e.target.value)}
        />
        <div style={{ textAlign: 'right' }}>
          <br />
          <button className='admin-button' type='submit'>
            Submit Invoices
          </button>
        </div>
      </form>
    </fieldset>
  )
}
